/* Reset some default browser styles */
body, h1, h2, h3, h4, h5, h6, p, ul, li, form, input, textarea, button {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

/* Set the background color and some basic styles */
body {
  background-color: #f4f4f9;
  color: #333;
  line-height: 1.6;
  font-size: 16px;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Make sure form elements look nice and consistent
input, textarea {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}
*/


/* Make buttons look nicer */
button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #555;
}
