
.resume {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    text-align: center;
    margin-top: 80px;
}

.resume-iframe {
    width: 90%; /* Adjust as necessary */
    height: 90%; /* Adjust as necessary */
    border: 1px solid black;
}


