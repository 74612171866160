.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Courier New', Courier, monospace; /* Courier New for regular text */
  line-height: 1.6;
  font-size: 16px;
}

.content {
  flex: 1;
}

.light-mode  {
  background-color: #fdfdfb;
  /* background: linear-gradient(to bottom, #fdfdfd, whitesmoke);
  /* background-color: whitesmoke; /* Light mode background color */
  color: black; /* Light mode font color */
}

.dark-mode  {
  /* background: linear-gradient(to bottom, #1c1e1f, black); */
  background-color: #1c1e1f;
  /* background-color: #0d0a20; */
    /* background-color: #051427; /* Dark mode background color */
  color: white; /* Dark mode font color */
}

html {
  background-color: #051427; /* Dark mode background color */
}



h1, h2, h3, h4, h5, h6 {
  font-family: Courier, monospace; /* Courier for titles and headers */

}

/* Non-header text */
p, ul, li, input, textarea, form, a /* Add other relevant selectors */ {
  font-family: Futura;
}

/*
<a target="_blank" href="https://icons8.com/icon/11450/planet">Planet</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>
 */