.projects {
    padding-top: 80px;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: center;
}

.project-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column; /* to allow 2 or more together, add display flex to div wrapping 2 tigether */
    justify-content: center;
}

.project-card {
    position: relative;
    width: min(90vw, 700px);
    height: 450px; /* Adjust as needed */

    background-color: whitesmoke;
    border: 4px solid indianred;
    margin: 10px auto 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-card.in-view {
    border: 4px solid black;
}


.dark-mode .project-card {
    /* background-color: #EDF2F3; */
    /* border: 2px solid #fff; */
}

.project-info {
    position: absolute;
    width: min(90vw, 700px);
    left: 0%; /* -2px will account for border counting as part of its width, but then have to add 2px to the width to account for left border */
    height: 100%;
    background-color: #ffaaa5; /* Green background */
    border: 4px solid indianred;
    display: flex;
    align-items: center;

    flex-direction: column;
    border-radius: 10px;
    transition: width 0.7s ease-in-out;
    z-index: 3;
}

.project-card.in-view .project-info {
    border: 4px solid black;
}

.dark-mode .project-info {
    /* background-color: #101b39; */
    /* border: 2px solid #fff; */
}

.project-info.in-view {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.project-title {
    font-size: 50px;
    font-weight: bold;
    color: #fff;
    padding-top: 100px;
    transition: font-size 0.7s ease-in-out;
    padding-left: 5px;
    padding-right: 5px;
}

.project-card.in-view .project-title {
    font-size: 32px;
}

.languages {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.language {
    background-color: #fff;
    color: #ffaaa5;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 2px;
    font-size: 20px;
    font-weight: bold;

    transition: font-size 0.7s ease-in-out, padding 0.7s ease-in-out;
}

.dark-mode .language {
    /* color: #101b39; */
}

.project-card.in-view .language {
    padding: 4px 8px;
    font-size: 16px;
}

.project-description {
    position: absolute;
    left: 100%; /* Adjust based on the padding and width of the card */
    width: calc(0.6 * (min(90vw, 700px) - 30px));
    height: 100%;
    padding-left: min(4vw, 15px); /* TODO: Make the padding relative to size, because big screens have barely any */
    padding-right: 5px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: left 0.7s ease-in-out;
    z-index: 2;
    flex-direction: column;
}

.project-description p {
    text-align: left;
    font-size: 18px;
    color: black;

}

.project-description .project-image {
    max-width: 100%;
    max-height: 50%; /* Adjust as needed */
    margin-bottom: 10px;
}

.project-card.in-view .project-info {
    width: calc(0.4 * min(90vw, 700px));
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.project-card.in-view .project-description {
    left: 40%;
}

.project-row {
    padding-left: 10px;
    padding-right: 10px;
}

.source-code {
    margin: 8px;
}

.source-code a {
    color: indianred;
    font-size: 16px;

}


/* Media Query for screens as large as 100px */
@media (max-width: 1000px) {
    .project-description p {
        font-size: 16px;
    }

    .project-title {
        font-size: 45px;
    }

    .project-card.in-view .project-title {
        font-size: 26px;
    }


    .project-card.in-view .language {
        padding: 5px 10px;
        font-size: 14px;
    }

}


/* Media Query for screens smaller than 500px */
@media (max-width: 610px) {
    .project-description p {
        font-size: 14px;
    }

    .project-title {
        font-size: 40px;
    }

    .project-card.in-view .project-title {
        font-size: 20px;
    }

    .project-card.in-view .language {
        padding: 5px 10px;
        font-size: 12px;
    }

    .project-card {
        height: 400px; /* Adjust as needed */
    }

    .source-code a {
        font-size: 12px;

    }
}

/* Media Query for screens smaller than 500px */
@media (max-width: 470px) {
    .project-description p {
        font-size: 12px;
    }

    .project-title {
        font-size: 35px;
    }

    .project-card.in-view .project-title {
        font-size: 18px;
    }

    .project-card.in-view .language {
        padding: 4px 8px;
        font-size: 10px;
    }
}

