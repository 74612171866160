.footer {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    bottom: 0;
    left: 0;
    margin-top: 35px;
}

.footer.dark-mode {
    box-shadow: 0 5px 20px white;
    background-color: lightpink;
}

.footer.light-mode {
    box-shadow: 0 5px 20px black;
    background-color: #ffaaa5;
}

.footer p {
    margin: 0;
    color: white; /* Adjust to fit your theme */
}
